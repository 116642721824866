import {
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowDownFromLine as farArrowDownFromLine,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightFromArc as farArrowRightFromArc,
    faArrowRightFromArc,
    faArrowRightFromBracket as farArrowRightFromBracket,
    faArrowRightToArc as farArrowRightToArc,
    faArrowRotateLeft,
    faArrowUp,
    faArrowUpFromLine as farArrowUpFromLine,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faBank,
    faBars,
    faBattery,
    faBell as farBell,
    faBook as farBook,
    faBookOpen as farBookOpen,
    faBoxArchive,
    faBriefcase as farBriefcase,
    faBriefcaseMedical as farBriefcaseMedical,
    faBuilding,
    faBuildings,
    faBusinessTime,
    faCalculator as farCalculator,
    faCalculatorSimple as farCalculatorSimple,
    faCalendar as farCalendar,
    faCalendarClock as farCalendarClock,
    faCalendarWeek as farCalendarWeek,
    faCalendarXmark,
    faCalendars as farCalendars,
    faCar,
    faChartLine as farChartLine,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircle as farCircle,
    faCircleCheck as farCircleCheck,
    faCircleDashed as farCircleDashed,
    faCircleEllipsis,
    faCircleExclamation as farCircleExclamation,
    faCircleInfo as farCircleInfo,
    faCircleQuestion,
    faClipboardList as farClipboardList,
    faClipboardListCheck as farClipboardListCheck,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faCoins as farCoins,
    faCommentDots,
    faCopy,
    faDesktop,
    faDollar,
    faDownload,
    faDumbbell,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEquals as farEquals,
    faEuroSign,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileCirclePlus,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFileInvoice as farFileInvoice,
    faFileLines as farFileLines,
    faFileMagnifyingGlass as farFileMagnifyingGlass,
    faFilePdf,
    faFilePlus,
    faFileSignature,
    faFileSpreadsheet,
    faFileXmark,
    faFileZip as farFileZip,
    faFiles as farFiles,
    faFiles,
    faFilter,
    faFloppyDisk as farFloppyDisk,
    faFolder as farFolder,
    faFolderOpen,
    faFolders as farFolders,
    faForward,
    faGavel,
    faGear as farGear,
    faGlobe as farGlobe,
    faGripDotsVertical,
    faHand,
    faHandHoldingDollar as farHandHoldingDollar,
    faHandWave as farHandWave,
    faHotel,
    faHourglassHalf as farHourglassHalf,
    faHouse as farHouse,
    faHouseBlank,
    faIdCard as farIdCard,
    faImage,
    faInputNumeric as farInputNumeric,
    faInputText as farInputText,
    faIslandTropical,
    faKey as farKey,
    faKey,
    faLaptopMobile as farLaptopMobile,
    faLightbulb,
    faLink,
    faList as farList,
    faListDropdown as farListDropdown,
    faLoader,
    faLocationDot,
    faLocationDotSlash,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMinusCircle as farMinusCircle,
    faMobile,
    faNote,
    faPaperclip,
    faPartyHorn,
    faPause,
    faPen,
    faPenField as farPenField,
    faPercent,
    faPercentage as farPercentage,
    faPhone,
    faPizzaSlice as farPizzaSlice,
    faPlane as farPlane,
    faPlay,
    faPlus,
    faPlusCircle as farPlusCircle,
    faPowerOff,
    faPuzzlePiece,
    faRepeat,
    faRobot as farRobot,
    faRocket as farRocket,
    faRotate,
    faRotateLeft,
    faSackDollar,
    faScaleBalanced,
    faSend,
    faShieldCheck,
    faShieldQuartered,
    faSidebar as farSidebar,
    faSignature,
    faSliders as farSliders,
    faSliders,
    faSmileWink as farSmileWink,
    faSms,
    faStar as farStar,
    faStethoscope,
    faSuitcase,
    faTablet,
    faThumbtack,
    faToggleOn as farToggleOn,
    faTrash,
    faTriangleExclamation as farTriangleExclamation,
    faTriangleExclamation,
    faUndo as farUndo,
    faUpload,
    faUser as farUser,
    faUserCheck as farUserCheck,
    faUserClock as farUserClock,
    faUserCrown,
    faUserGraduate as farUserGraduate,
    faUserGroup as farUserGroup,
    faUserHelmetSafety as farUserHelmetSafety,
    faUserMagnifyingGlass as farUserMagnifyingGlass,
    faUserPen,
    faUserPlus as farUserPlus,
    faUserSlash as farUserSlash,
    faUserTie as farUserTie,
    faUserVneckHair as farUserVneckHair,
    faUserXmark as farUserXmark,
    faUserXmark,
    faUsers as farUsers,
    faUsers,
    faUtensils,
    faVideo,
    faWandMagicSparkles,
    faWarning as farWarning,
    faXmark,
    faXmarkToSlot,
    faPlugCircleMinus,
    faPlugCirclePlus,
    faArchive,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faArrowDownFromLine as fasArrowDownFromLine,
    faArrowRightArrowLeft,
    faArrowRightFromBracket as fasArrowRightFromBracket,
    faArrowUpFromLine as fasArrowUpFromLine,
    faBars as fasBars,
    faBell as fasBell,
    faBook as fasBook,
    faBookOpen as fasBookOpen,
    faBriefcase as fasBriefcase,
    faBuilding as fasBuilding,
    faBuildings as fasBuildings,
    faCalculator as fasCalculator,
    faCalendarWeek as fasCalendarWeek,
    faCalendars as fasCalendars,
    faCaretDown,
    faCaretUp,
    faChartLine as fasChartLine,
    faCircleCheck as fasCircleCheck,
    faCircleDashed as fasCircleDashed,
    faCircleExclamation as fasCircleExclamation,
    faCircleInfo as fasCircleInfo,
    faCircleXmark as fasCircleXmark,
    faClock as fasClock,
    faCoins as fasCoins,
    faFileCheck as fasFileCheck,
    faFileCirclePlus as fasFileCirclePlus,
    faFileLines as fasFileLines,
    faFileSignature as fasFileSignature,
    faFiles as fasFiles,
    faFolder as fasFolder,
    faGear as fasGear,
    faHouse as fasHouse,
    faInputNumeric as fasInputNumeric,
    faInputText as fasInputText,
    faKey as fasKey,
    faLaptopMobile as fasLaptopMobile,
    faList as fasList,
    faListDropdown as fasListDropdown,
    faMars,
    faPen as fasPen,
    faPenField as fasPenField,
    faPizzaSlice as fasPizzaSlice,
    faPuzzlePiece as fasPuzzlePiece,
    faRocket as fasRocket,
    faScaleBalanced as fasScaleBalanced,
    faShieldCheck as fasShieldCheck,
    faShieldQuartered as fasShieldQuartered,
    faSliders as fasSliders,
    faSquare1,
    faSquare2,
    faThumbtack as fasThumbtack,
    faToggleOn as fasToggleOn,
    faTriangleExclamation as fasTriangleExclamation,
    faUser as fasUser,
    faUserGroup as fasUserGroup,
    faUserHelmetSafety as fasUserHelmetSafety,
    faUserPlus as fasUserPlus,
    faUsers as fasUsers,
    faVenus,
    faVideo as fasVideo,
    faPlugCircleMinus as fasPlugCircleMinus,
    faPlugCirclePlus as fasPlugCirclePlus,
    faArchive as fasArchive,
} from '@fortawesome/pro-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

library.add(
    faAdd,
    faAddressCard,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faArrowDown,
    faArrowDownRight,
    faArrowDownToLine,
    faArrowLeft,
    faArrowRight,
    faArrowRightArrowLeft,
    faArrowRightFromArc,
    faArrowRotateLeft,
    faArrowUp,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowsRotate,
    faBank,
    faBars,
    faBattery,
    faBoxArchive,
    faBuilding,
    faBuildings,
    faBusinessTime,
    faCalendarXmark,
    faCar,
    faCaretDown,
    faCaretUp,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faCircleEllipsis,
    faCircleQuestion,
    faClock,
    faClockThree,
    faClose,
    faCoin,
    faCommentDots,
    faCopy,
    faDesktop,
    faDollar,
    faDownload,
    faDumbbell,
    faEdit,
    faEllipsis,
    faEnvelope,
    faEnvelopesBulk,
    faEuroSign,
    faExternalLink,
    faEye,
    faEyeSlash,
    faFile,
    faFileAlt,
    faFileCheck,
    faFileCirclePlus,
    faFileContract,
    faFileCsv,
    faFileDoc,
    faFileImport,
    faFilePdf,
    faFilePlus,
    faFileSignature,
    faFileSpreadsheet,
    faFileXmark,
    faFiles,
    faFilter,
    faFolderOpen,
    faForward,
    faGavel,
    faGripDotsVertical,
    faHand,
    faHotel,
    faHouseBlank,
    faImage,
    faIslandTropical,
    faKey,
    faLightbulb,
    faLink,
    faLoader,
    faLocationDot,
    faLocationDotSlash,
    faLock,
    faLockOpen,
    faMagnifyingGlass,
    faMagnifyingGlassPlus,
    faMars,
    faMobile,
    faNote,
    faPaperclip,
    faPartyHorn,
    faPause,
    faPen,
    faPercent,
    faPhone,
    faPlay,
    faPlus,
    faPowerOff,
    faPuzzlePiece,
    faRepeat,
    faRotate,
    faRotateLeft,
    faSackDollar,
    faScaleBalanced,
    faSend,
    faShieldCheck,
    faShieldQuartered,
    faSignature,
    faSliders,
    faSms,
    faSquare1,
    faSquare2,
    faStethoscope,
    faSuitcase,
    faTablet,
    faThumbtack,
    faTrash,
    faTriangleExclamation,
    faUpload,
    faUserCrown,
    faUserPen,
    faUserXmark,
    faUsers,
    faUtensils,
    faVenus,
    faVideo,
    faWandMagicSparkles,
    faXmark,
    faXmarkToSlot,
    farArrowDownFromLine,
    farArrowRightFromArc,
    farArrowRightFromBracket,
    farArrowRightToArc,
    farArrowUpFromLine,
    farBell,
    farBook,
    farBookOpen,
    farBriefcase,
    farBriefcaseMedical,
    farCalculator,
    farCalculatorSimple,
    farCalendar,
    farCalendarClock,
    farCalendarWeek,
    farCalendars,
    farChartLine,
    farCircle,
    farCircleCheck,
    farCircleDashed,
    farCircleExclamation,
    farCircleInfo,
    farClipboardList,
    farClipboardListCheck,
    farCoins,
    farEquals,
    farFileInvoice,
    farFileLines,
    farFileMagnifyingGlass,
    farFileZip,
    farFiles,
    farFloppyDisk,
    farFolder,
    farFolders,
    farGear,
    farGlobe,
    farHandHoldingDollar,
    farHandWave,
    farHourglassHalf,
    farHouse,
    farIdCard,
    farInputNumeric,
    farInputText,
    farKey,
    farLaptopMobile,
    farList,
    farListDropdown,
    farMinusCircle,
    farPenField,
    farPercentage,
    farPizzaSlice,
    farPlane,
    farPlusCircle,
    farRobot,
    farRocket,
    farSidebar,
    farSliders,
    farSmileWink,
    farStar,
    farToggleOn,
    farTriangleExclamation,
    farUndo,
    farUser,
    farUserCheck,
    farUserClock,
    farUserGraduate,
    farUserGroup,
    farUserHelmetSafety,
    farUserMagnifyingGlass,
    farUserPlus,
    farUserSlash,
    farUserTie,
    farUserVneckHair,
    farUserXmark,
    farUsers,
    farWarning,
    fasArrowDownFromLine,
    fasArrowRightFromBracket,
    fasArrowUpFromLine,
    fasBars,
    fasBell,
    fasBook,
    fasBookOpen,
    fasBriefcase,
    fasBuilding,
    fasBuildings,
    fasCalculator,
    fasCalendarWeek,
    fasCalendars,
    fasChartLine,
    fasCircleCheck,
    fasCircleDashed,
    fasCircleExclamation,
    fasCircleInfo,
    fasCircleXmark,
    fasClock,
    fasCoins,
    fasFileCheck,
    fasFileCirclePlus,
    fasFileLines,
    fasFileSignature,
    fasFiles,
    fasFiles,
    fasFolder,
    fasGear,
    fasHouse,
    fasInputNumeric,
    fasInputText,
    fasKey,
    fasLaptopMobile,
    fasList,
    fasListDropdown,
    fasPen,
    fasPenField,
    fasPizzaSlice,
    fasPuzzlePiece,
    fasRocket,
    fasScaleBalanced,
    fasShieldCheck,
    fasShieldQuartered,
    fasSliders,
    fasThumbtack,
    fasToggleOn,
    fasTriangleExclamation,
    fasUser,
    fasUserGroup,
    fasUserHelmetSafety,
    fasUserPlus,
    fasUsers,
    fasVideo,
    faPlugCircleMinus,
    faPlugCirclePlus,
    faArchive,
    fasPlugCircleMinus,
    fasPlugCirclePlus,
    fasArchive,
);
